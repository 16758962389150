
    import AbpBase from "../../../../lib/abpbase";
    import {Component, Prop} from "vue-property-decorator";

    @Component
    export default class JobAuditingDetails extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;

        visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            }
        }

        get list() {
            return this.$store.state.auditing.details;
        };

        columns = [{
            title: this.L('Property'),
            key: 'propertyName'
        }, {
            title: this.L('Original value'),
            key: 'originalValue'
        }, {
            title: this.L('New value'),
            key: 'newValue'
        }]
    }
